import React from 'react';
import { Field } from 'formik';
import { Select } from '@mantine/core';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffe8e8;
  border-width: 1px;
  border-color: #d8000c;
  border-radius: 4px;
  padding: 10px;
`;

const ErrorText = styled.div`
  color: #d8000c;
  font-size: 14px;
  font-weight: bold;
`;

const SelectInput = ({ label, name, placeholder, options, cleared, withAsterisk, disabled }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const selectedOption = options.find((option) => option.id === field.value);

        const handleChange = (option) => {
          const selectedOption = options.find((opt) => opt.name === option);
          form.setFieldValue(name, selectedOption ? selectedOption.id : '');
        };

        return (
          <div>
            <Select
              label={label}
              placeholder={placeholder}
              data={options.map((option) => option.name)}
              value={selectedOption ? selectedOption.name : ''}
              onChange={handleChange}
              searchable
              disabled={disabled}
              clearable={cleared}
              withAsterisk={withAsterisk}
              error={form.touched[name] && form.errors[name] ? form.errors[name] : false}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default SelectInput;
