import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit
  },
  doneButton: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing.unit,
    color: 'white',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.customMain,
      color: 'white' // Add your custom color for disabled state
    }
  }
});

const LoadingButton = (props) => {
  const { classes, loading, done, icon, fullWidth, ...other } = props;
  const theme = useTheme();

  if (done) {
    return (
      <Button className={classes.doneButton} fullWidth={fullWidth} {...other} disabled>
        <CheckCircleIcon size={40} sx={{ fontSize: '1.6rem' }} />
      </Button>
    );
  } else if (loading) {
    return (
      <Button className={classes.button} fullWidth={fullWidth} {...other} disabled={loading}>
        <CircularProgress size={24} />
      </Button>
    );
  } else {
    return <Button className={classes.button} fullWidth={fullWidth} {...other} />;
  }
};

LoadingButton.defaultProps = {
  loading: false,
  done: false,
  fullWidth: false
};

LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  done: PropTypes.bool,
  icon: PropTypes.element,
  fullWidth: PropTypes.bool
};

export default withStyles(styles)(LoadingButton);
