import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from 'store/features/api/apiSlice';

export const workerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createWorker: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append('firstName', data?.firstName || '');
        formData.append('lastName', data?.lastName || '');
        formData.append('homeAddress', data?.address || '');
        formData.append('phone', data?.phone || '');
        formData.append('paymentPeriod', data?.payment || '');
        formData.append('salary', data?.salary || 0);
        formData.append('role', data?.role || '');
        formData.append('image', data?.image || '');
        formData.append('username', data?.username || null);
        formData.append('password', data?.password || null);

        return {
          url: 'salon/create-worker',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['worker']
    }),
    getWorkerById: builder.query({
      query: (id) => `salon/workerId/${id}`,
      // refetchOnMount: 'always',
      // refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data
    }),

    updateWorkerDetails: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append('firstName', data?.firstName || '');
        formData.append('lastName', data?.lastName || '');
        formData.append('homeAddress', data?.address || '');
        formData.append('phone', data?.phone || '');
        formData.append('paymentPeriod', data?.payment || '');
        formData.append('salary', data?.salary || '');
        formData.append('role', data?.role || '');
        formData.append('image', data?.image || '');
        formData.append('workerId', data?.workerId || '');
        return {
          url: 'salon/update-worker',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['worker']
    }),
    payWorker: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append('amount', data?.amount || '');
        formData.append('comment', data?.comment || '');
        formData.append('paymentType', data?.paymentType || '');
        formData.append('salonWorkerId', data?.salonWorkerId || '');
        return {
          url: 'salon/add-payment-to-employee',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['worker']
    }),

    getAllWorker: builder.query({
      query: () => 'salon/get-workers',
      refetchOnReconnect: true,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        (response.data && response.data.count > 0 && response?.data?.rows.sort((a, b) => b.id - a.id)) || [],
      providesTags: ['worker']
    }),
    AddEmployeeWork: builder.mutation({
      query: (data) => {
        return {
          url: 'salon/add-employee-work',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    approveServiceCancel: builder.mutation({
      query: (data) => {
        return {
          url: 'salon/approve-cancellation',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getAllEmployeeWorks: builder.query({
      query: (filterDates) => {
        let apiUrl = 'salon/get-employee-works?';
        if (filterDates?.from && filterDates?.to !== null) {
          apiUrl += `from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        if (filterDates?.user !== null || undefined) {
          apiUrl += `&workerId=${filterDates?.user}`;
        }
        return apiUrl;
      },
      // refetchOnReconnect: true,
      retry: 3,
      // retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => (response.data && response.data.count > 0 && response?.data?.rows) || [],
      providesTags: ['worker'],
      overrideExisting: true
    }),
    getSalonPayments: builder.query({
      query: (filterDates) => {
        let apiUrl = 'salon/get-salon-payments?';
        if (filterDates?.from && filterDates?.to !== null) {
          apiUrl += `from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        if (filterDates?.user !== null || undefined) {
          apiUrl += `&workerId=${filterDates?.user}`;
        }
        return apiUrl;
      },
      // refetchOnReconnect: true,
      retry: 3,
      // retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => (response.data && response.data.count > 0 && response?.data?.rows) || [],
      providesTags: ['worker'],
      overrideExisting: true
    })
  })
});

export const {
  useCreateWorkerMutation,
  useGetAllWorkerQuery,
  useUpdateWorkerDetailsMutation,
  useGetWorkerByIdQuery,
  useApproveServiceCancelMutation,
  useAddEmployeeWorkMutation,
  usePayWorkerMutation,
  useLazyGetSalonPaymentsQuery,
  useLazyGetAllEmployeeWorksQuery
} = workerApi;

const workerSlice = createSlice({
  name: 'worker',
  initialState: {
    worker: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(workerApi.endpoints.getAllWorker.matchFulfilled, (state, action) => {});
  }
});

export const workerReducer = workerSlice.reducer;

export default workerSlice;
