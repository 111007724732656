import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';

export const salesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSales: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/sales/create',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['sales'],
      overrideExisting: true
    }),
    createBarSales: builder.mutation({
      query: (data) => {
        return {
          url: '/bar/sales/create',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['sales'],
      overrideExisting: true
    }),
    createProforma: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/sales/create-proforma',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      overrideExisting: true
    }),
    makeRefund: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/sales/make-refund',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      overrideExisting: true
    }),
    printReceipt: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/sales/make-print',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      overrideExisting: true
    }),
    getAllSales: builder.query({
      query: (filterDates) => {
        let apiUrl = 'shop/sales/all?limit=100&closing=all';
        if (filterDates && filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        if (filterDates && filterDates?.productId && filterDates?.productId !== null) {
          apiUrl += `&productId=${filterDates.productId}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getAllProforma: builder.query({
      query: (filterDates) => {
        let apiUrl = 'shop/sales/all-proforma?limit=100&closing=all';
        if (filterDates && filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        if (filterDates && filterDates?.productId && filterDates?.productId !== null) {
          apiUrl += `&productId=${filterDates.productId}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      overrideExisting: true
    }),
    getSalesTotals: builder.query({
      query: (filterDates) => {
        let apiUrl = 'shop/totals/salesTotal?name=salesReport';
        if (filterDates && filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getBarDrafts: builder.query({
      query: (filterDates) => {
        let apiUrl = 'bar/sales/draft';
        if (filterDates && filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getBarUsers: builder.query({
      query: (filterDates) => {
        let apiUrl = 'user/all';
        if (filterDates && filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    })
  })
});

export const {
  useCreateSalesMutation,
  useCreateProformaMutation,
  useGetAllSalesQuery,
  useCreateBarSalesMutation,
  useLazyGetAllSalesQuery,
  useLazyGetAllProformaQuery,
  useLazyGetSalesTotalsQuery,
  useMakeRefundMutation,
  useGetBarDraftsQuery,
  useGetBarUsersQuery,
  usePrintReceiptMutation
} = salesApi;

const salesSlice = createSlice({
  name: 'sales',
  initialState: {
    sales: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(salesApi.endpoints.createSales.matchFulfilled, (state, action) => {
      // console.log(action.payload.data);
    });
  }
});

export const salesReducer = salesSlice.reducer;

export default salesSlice;
