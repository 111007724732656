import { Box, Grid, Card, CardContent, Radio, FormControlLabel, Checkbox, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { subscriptionData } from './Assets/FakeData';
import CustomSelect from './inputs/Select';
import CustomNumberInput from './inputs/NumberInput';
import { CustomInput } from './inputs/TextInput';
import { Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { Dialog, Group, Modal, TextInput, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

function Payment() {
  const theme = useTheme();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Dialog position={{ bottom: '20%', right: '15%' }} opened={opened} withCloseButton onClose={close} size="lg" radius="md">
        <Typography variant="h6" sx={{ color: '#2D3748' }}>
          Comfirm Payment
        </Typography>

        <Typography variant="subtitle2" sx={{ color: '#000000', marginTop: '0.5rem' }}>
          After you Confirm, you’ll be prompted to submit your Mobile money PIN on your phone to complete the payment
        </Typography>
        <Button
          sx={{ backgroundColor: theme.palette.primary.customMain, color: 'white', marginTop: '1rem' }}
          fullWidth
          size="small"
          type="submit"
          variant="contained"
        >
          Comfirm
        </Button>
      </Dialog>

      <Formik
        initialValues={{
          category: '',
          phoneNumber: '',
          dial: '*182*8*1*5473478#',
          payWith: 'Momo',
          checked: false,
          price: ''
        }}
        validationSchema={Yup.object().shape({
          category: Yup.string().required('Category is required'),
          phoneNumber: Yup.string().required('Phone Number is required'),
          checked: Yup.boolean().oneOf([true], 'Please agree to the terms and conditions')
        })}
        onSubmit={(values, { setSubmitting }) => {
          // Handle form submission
          setSubmitting(false);
          open();
        }}
      >
        {({ values, setFieldValue, isSubmitting, errors, touched }) => {
          return (
            <Form>
              <Grid container spacing={0} sx={{ minHeight: '100vh', padding: 0, margin: 0 }}>
                <Grid item xs={6} sx={{ backgroundColor: '#E3E8EF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div>
                    <StyledHeader>Choose Category</StyledHeader>
                    <StyledGrid
                      borderError={errors.category && touched.category}
                      container
                      spacing={2}
                      direction="column"
                      alignItems="center"
                    >
                      {subscriptionData.map((subscription) => (
                        <Grid item xs={12} key={subscription.key}>
                          <StyledCard>
                            <CardContent>
                              <FormControlLabel
                                value={subscription.key}
                                control={<Radio />}
                                label={
                                  <>
                                    <div>
                                      <strong>{subscription.label}</strong>
                                    </div>
                                    <div>Price: {subscription.price}</div>
                                    <div>{subscription.details}</div>
                                  </>
                                }
                                checked={values.category === subscription.key}
                                onChange={() => {
                                  setFieldValue('category', subscription.key);
                                  setFieldValue('price', subscription.price);
                                }}
                              />
                            </CardContent>
                          </StyledCard>
                        </Grid>
                      ))}
                    </StyledGrid>
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div>
                    <StyledHeaderFill>Fill To Pay</StyledHeaderFill>
                    <StyledGridFill container spacing={0} direction="column" alignItems="center">
                      <Grid item xs={12}>
                        <StyledCardFill>
                          <CardContent>
                            <CustomSelect
                              name="payWith"
                              label="Payment With"
                              placeholder="Select Payment"
                              options={
                                [
                                  { id: 'Momo', label: 'Mobile Money' },
                                  { id: 'airtel', label: 'Airtel Money' },
                                  { id: 'card', label: 'Card' }
                                ] || []
                              }
                              cleared={true}
                              withAsterisk={false}
                            />
                          </CardContent>
                        </StyledCardFill>
                      </Grid>
                      <Grid item xs={12}>
                        <StyledCardFill>
                          <CardContent>
                            <CustomInput
                              name="dial"
                              label="Dial this on your MTN phone to pay"
                              placeholder="Dial this on your MTN phone to pay"
                              readOnly={true}
                            />
                          </CardContent>
                        </StyledCardFill>
                      </Grid>
                      <Grid item xs={12}>
                        <StyledCardFill>
                          <CardContent>
                            <CustomInput
                              name="phoneNumber"
                              label="Enter your MTN MoMo Phone number to pay"
                              placeholder="Enter your MTN MoMo Phone number to pay"
                              readOnly={false}
                            />
                          </CardContent>
                        </StyledCardFill>
                      </Grid>
                      <Grid item xs={12}>
                        <StyledCardFill>
                          <CardContent>
                            <Field name="checked">
                              {({ field, form }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      classes={{ root: errors.checked && touched.checked ? 'error-checkbox' : 'primary-checkbox' }}
                                    />
                                  }
                                  label={
                                    <span style={{ color: errors.checked && touched.checked ? 'red' : 'inherit' }}>
                                      Before creating an account, please Agree to our &nbsp;
                                      <Link to="#" style={{ color: errors.checked && touched.checked ? 'red' : 'inherit' }}>
                                        Terms and Conditions
                                      </Link>
                                    </span>
                                  }
                                />
                              )}
                            </Field>
                          </CardContent>
                        </StyledCardFill>
                      </Grid>
                      <Grid item xs={12}>
                        <StyledCardFill>
                          <Button
                            sx={{ backgroundColor: theme.palette.primary.customMain, color: 'white' }}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {` Pay ${values.price}`}
                          </Button>
                        </StyledCardFill>
                      </Grid>
                    </StyledGridFill>
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default Payment;

const StyledCard = styled(Card)({
  border: '1px solid #4B7DF3',
  color: 'black',
  width: '350px',
  marginBottom: '10px'
});

const StyledCardFill = styled(Card)({
  color: 'black',
  width: '400px',
  marginBottom: '10px'
});

const StyledHeader = styled('h1')({
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '3rem',
  textAlign: 'center'
});

const StyledHeaderFill = styled('h1')({
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '1rem',
  textAlign: 'center'
});

const StyledGrid = styled(Grid)(({ borderError }) => ({
  border: `1px solid ${borderError ? 'red' : '#FFFFFF'}`,
  borderRadius: '8px',
  padding: '1rem 3rem '
}));

const StyledGridFill = styled(Grid)({
  border: '1px solid rgba(18, 9, 88, 0.3)',
  borderRadius: '8px',
  padding: '1rem 3rem '
});
