import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router';
import logo from 'assets/images/Llogo.svg';
import BillTable from 'component/BillTable';
import { useTheme } from '@mui/material/styles';
import PrintIcon from '@mui/icons-material/Print';
import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DataTableWithTitle from 'component/DataTable';
import { determinePaymentType } from 'component/Assets/FakeData';
import { usePrintReceiptMutation } from 'store/features/slices/salesSlicee';
import { useLazyGetCompanyQuery } from 'store/features/slices/companySlice';
import { Box, Button, Grid, IconButton, Typography, Tab, Tabs } from '@mui/material';

export default function SellingBill({ billInfo }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [hide, setHide] = useState('');
  const paymentType = determinePaymentType(billInfo);
  const [printRequest, { data }] = usePrintReceiptMutation();
  const userDetails = useSelector((state) => state.user.info);
  const [getCompany, companyResponse] = useLazyGetCompanyQuery();

  useEffect(() => {
    if (billInfo?.saleCopyNum === 1) setHide('print');
    if (userDetails.companyId) getCompany({ id: userDetails.companyId });
  }, []);
  if (hide === 'all' && data?.status) window.location.reload();

  const handlePrint = (value = '') => {
    const content = document.getElementById(`content-to-${value}`).innerHTML;
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`<html><head><title>${billInfo?.refCode}</title></head><body>`);
    newWindow.document.write(content);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    newWindow.print();
    setTimeout(() => {
      newWindow.close();
    }, 1000);

    printRequest({
      saleId: billInfo?.id,
      _type: billInfo?.recType === 'NR' ? billInfo?.recType : 'NS'
    });

    if (hide === '') setHide(value);
    else if (hide !== value) setHide('all');
  };

  const Line = <div style={{ borderBottom: '2px dotted', margin: '5px 0 5px 0' }} />;
  const Right = ({ children }) => <span style={{ float: 'right' }}>{children}</span>;
  const Flex = ({ children }) => <div style={{ display: 'flex' }}>{children}</div>;
  const Flex1 = ({ children }) => <div style={{ flex: 2 }}>{children}</div>;
  const Flex2 = ({ children }) => <div style={{ flex: 1, textAlign: 'center' }}>{children}</div>;
  const Flex3 = ({ children }) => <div style={{ flex: 1, textAlign: 'right' }}>{children}</div>;

  let totalA = 0;
  let totalB = 0;

  return (
    <>
      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={tab} onChange={() => {}} aria-label="basic tabs example">
					<Tab label="EBM Design" onClick={() => setTab(0)} />
					<Tab label="Original Design" onClick={() => setTab(1)} />
				</Tabs>
			</Box> */}
      <div style={{ display: !tab ? 'block' : 'none' }}>
        <div id="content-to-print">
          <div
            style={{
              padding: 20,
              border: '2px solid',
              maxWidth: 500,
              margin: 'auto'
            }}
          >
            <div style={{ textAlign: 'center' }}>
              {companyResponse?.data?.data?.name} <br />
              {companyResponse?.data?.data?.locations} <br />
              TIN: {companyResponse?.data?.data?.tin} <br />
              <b>
                {billInfo?.recType === 'TS' ? 'TRAINING MODE' : ''} {billInfo?.title}
              </b>
            </div>
            {Line}
            {billInfo?.isRefunded === '1' ? (
              <>
                <div style={{ textAlign: 'center' }}>
                  <b>REFUND</b>
                </div>
                <div>
                  REF. NORMAL RECEIPT#:<Right>---</Right>
                </div>
                {Line}
                <div style={{ textAlign: 'center' }}>
                  REFUND IS APPROVED ONLY FOR <br />
                  ORIGINAL SALES RECEIPT <br />
                  Client ID: {billInfo?.clientName}
                </div>
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                Welcome to our shop <br />
                Client ID: {billInfo?.clientName}
              </div>
            )}
            {Line}
            {billInfo?.products?.map((item) => {
              if (item?.tax === 'A') totalA += item?.totalPrice || item?.unitPrice * item?.qty;
              if (item?.tax === 'B') totalB += item?.totalPrice || item?.unitPrice * item?.qty;
              return (
                <div key={item?.productId}>
                  {item?.productName} <br />
                  <Flex>
                    <Flex1>{item?.unitPrice} x</Flex1>
                    <Flex2>{item?.qty}</Flex2>
                    <Flex3>
                      {item?.totalPrice || item?.unitPrice * item?.qty}
                      {item?.tax === 'A' ? 'A-EX' : item?.tax}
                    </Flex3>
                  </Flex>
                </div>
              );
            })}
            {Line}
            {billInfo?.recType === 'TS' && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <b>THIS IS NOT AN OFFICIAL RECEIPT</b>
                </div>
                {Line}
              </>
            )}
            <div>
              <b>TOTAL</b>
              <b style={{ float: 'right' }}>{billInfo?.salesTotal}</b>
            </div>
            <div>
              TOTAL A-EX<Right>{totalA}</Right>
            </div>
            <div>
              TOTAL B-18.00%
              <Right>{totalB}</Right>
            </div>
            <div>
              TOTAL TAX B<Right>{totalB * 0.18}</Right>
            </div>
            <div>
              TOTAL TAX<Right>{totalB * 0.18}</Right>
            </div>
            {Line}
            <div>
              CASH<Right>---</Right>
            </div>
            <div>
              ITEMS NUMBER<Right>{billInfo?.products?.length}</Right>
            </div>
            {Line}
            {(billInfo?.title === 'COPY' || billInfo?.recType === 'TS') && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <b>
                    {billInfo?.recType === 'TS' ? 'TRAINING MODE' : ''} {billInfo?.title}
                  </b>
                </div>
                {Line}
              </>
            )}
            <div style={{ textAlign: 'center' }}>VSDC INFORMATION</div>
            <div>
              DATE: ---<Right>Time: ---</Right>
            </div>
            <div>
              VSDC ID: <Right>---</Right>
            </div>
            <div>
              RECEIPT NUMBER: <Right>---</Right>
            </div>
            {Line}
            <div>
              RECEIPT NUMBER: <Right>{billInfo?.refCode}</Right>
            </div>
            <div>
              DATE: {billInfo?.createdAt?.split('T')[0]}
              <Right>TIME: {billInfo?.createdAt?.split('T')[1]?.slice(0, 8)}</Right>
            </div>
            <div>
              MRC: <Right>---</Right>
            </div>
            {Line}
            <div style={{ textAlign: 'center' }}>
              THANK YOU <br /> WE APPRECIATE YOUR BUSINESS
            </div>
          </div>
        </div>
        <div id="content-to-copy" style={{ display: 'none' }}>
          <div
            style={{
              padding: 20,
              border: '2px solid',
              maxWidth: 500,
              margin: 'auto'
            }}
          >
            <div style={{ textAlign: 'center' }}>
              {companyResponse?.data?.data?.name} <br />
              {companyResponse?.data?.data?.locations} <br />
              TIN: {companyResponse?.data?.data?.tin} <br />
              <b>{billInfo?.recType === 'TS' ? 'TRAINING MODE' : ''} COPY</b>
            </div>
            {Line}
            {billInfo?.isRefunded === '1' ? (
              <>
                <div style={{ textAlign: 'center' }}>
                  <b>REFUND</b>
                </div>
                <div>
                  REF. NORMAL RECEIPT#:<Right>---</Right>
                </div>
                {Line}
                <div style={{ textAlign: 'center' }}>
                  REFUND IS APPROVED ONLY FOR <br />
                  ORIGINAL SALES RECEIPT <br />
                  Client ID: {billInfo?.clientName}
                </div>
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                Welcome to our shop <br />
                Client ID: {billInfo?.clientName}
              </div>
            )}
            {Line}
            {billInfo?.products?.map((item) => {
              if (item?.tax === 'A') totalA += item?.totalPrice || item?.unitPrice * item?.qty;
              if (item?.tax === 'B') totalB += item?.totalPrice || item?.unitPrice * item?.qty;
              return (
                <div key={item?.productId}>
                  {item?.productName} <br />
                  <Flex>
                    <Flex1>{item?.unitPrice} x</Flex1>
                    <Flex2>{item?.qty}</Flex2>
                    <Flex3>
                      {item?.totalPrice || item?.unitPrice * item?.qty}
                      {item?.tax === 'A' ? 'A-EX' : item?.tax}
                    </Flex3>
                  </Flex>
                </div>
              );
            })}
            {Line}
            {billInfo?.recType === 'TS' && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <b>THIS IS NOT AN OFFICIAL RECEIPT</b>
                </div>
                {Line}
              </>
            )}
            <div>
              <b>TOTAL</b>
              <b style={{ float: 'right' }}>{billInfo?.salesTotal}</b>
            </div>
            <div>
              TOTAL A-EX<Right>{totalA}</Right>
            </div>
            <div>
              TOTAL B-18.00%
              <Right>{totalB}</Right>
            </div>
            <div>
              TOTAL TAX B<Right>{totalB * 0.18}</Right>
            </div>
            <div>
              TOTAL TAX<Right>{totalB * 0.18}</Right>
            </div>
            {Line}
            <div>
              CASH<Right>---</Right>
            </div>
            <div>
              ITEMS NUMBER<Right>{billInfo?.products?.length}</Right>
            </div>
            {Line}
            <div style={{ textAlign: 'center' }}>
              <b>{billInfo?.recType === 'TS' ? 'TRAINING MODE' : ''} COPY</b>
            </div>
            {Line}
            <div style={{ textAlign: 'center' }}>VSDC INFORMATION</div>
            <div>
              DATE: ---<Right>Time: ---</Right>
            </div>
            <div>
              VSDC ID: <Right>---</Right>
            </div>
            <div>
              RECEIPT NUMBER: <Right>---</Right>
            </div>
            {Line}
            <div>
              RECEIPT NUMBER: <Right>{billInfo?.refCode}</Right>
            </div>
            <div>
              DATE: {billInfo?.createdAt?.split('T')[0]}
              <Right>TIME: {billInfo?.createdAt?.split('T')[1]?.slice(0, 8)}</Right>
            </div>
            <div>
              MRC: <Right>---</Right>
            </div>
            {Line}
            <div style={{ textAlign: 'center' }}>
              THANK YOU <br /> WE APPRECIATE YOUR BUSINESS
            </div>
          </div>
        </div>
        <br />
        {hide !== 'all' && (
          <Flex>
            {hide !== 'copy' && hide !== '' && (
              <Flex2>
                <Button variant="contained" onClick={() => handlePrint('copy')}>
                  COPY
                </Button>
              </Flex2>
            )}
            {hide !== 'print' && (
              <Flex2>
                <Button variant="contained" onClick={() => handlePrint('print')}>
                  PRINT
                </Button>
              </Flex2>
            )}
          </Flex>
        )}
      </div>

      <div style={{ display: tab === 1 ? 'block' : 'none' }}>
        <Grid container style={{ display: 'block' }}>
          <StyledDiv borderYes={true} borderWidth={6}>
            <div>
              <div>
                <img src={logo} alt="Logo" width="40" height="auto" />
              </div>
              <SupplierDetails>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.primary.black,
                    marginRight: '5px',
                    fontWeight: 'bold'
                  }}
                >
                  TIN:
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.primary.black,
                    fontWeight: 'bold'
                  }}
                >
                  138436
                </Typography>
              </SupplierDetails>
              <Typography variant="subtitle2" sx={{ color: '#78756E' }}>
                {billInfo?.saleDate || 'N/A'}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" sx={{ color: '#78756E' }}>
                Invoice for
              </Typography>
              <Typography variant="h3" sx={{ color: theme.palette.primary.black, fontWeight: 'bold' }}>
                {billInfo?.clientName || 'N/A'}
              </Typography>
              <SupplierDetails>
                <Typography variant="subtitle2" sx={{ color: '#000000', marginRight: '5px' }}>
                  TIN:
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#000000' }}>
                  {billInfo?.clientTin || 'N/A'}
                </Typography>
              </SupplierDetails>
              <SupplierDetails>
                <Typography variant="subtitle2" sx={{ color: '#000000', marginRight: '5px' }}>
                  Address:
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#000000' }}>
                  {billInfo?.clientAddress || 'N/A'}
                </Typography>
              </SupplierDetails>
              <SupplierDetails>
                <Typography variant="subtitle2" sx={{ color: '#000000', marginRight: '5px' }}>
                  Email:
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#000000' }}>
                  {billInfo?.clientEmail || 'N/A'}
                </Typography>
              </SupplierDetails>
            </div>
          </StyledDiv>
          <StyledDiv borderYes={true} borderWidth={2} borderBottom={0.6}>
            <Typography variant="h3" sx={{ color: theme.palette.primary.black, fontWeight: 'bold' }}>
              Product
            </Typography>
          </StyledDiv>
          <StyledDiv borderYes={true} borderWidth={6} borderBottom={2}>
            <BillTable billInfo={billInfo} />
          </StyledDiv>
          <StyledBottomDiv>
            <div style={{ flex: 1 }}>
              <Typography variant="h3" sx={{ color: theme.palette.primary.black, fontWeight: 'bold' }}>
                Payable To
              </Typography>
            </div>

            <div style={{ flex: 1 }}>
              <ExtendedDetails>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.black,
                    fontWeight: 'bold'
                  }}
                >
                  Type of Payment
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.primary.black }}>
                  {paymentType || 'N/A'}
                </Typography>
              </ExtendedDetails>
              <ExtendedDetails>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.black,
                    fontWeight: 'bold'
                  }}
                >
                  Number Under
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.primary.black }}>
                  {billInfo?.clientName || 'N/A'}
                </Typography>
              </ExtendedDetails>
              <ExtendedDetails>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.black,
                    fontWeight: 'bold'
                  }}
                >
                  Telephone number
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.primary.black }}>
                  {billInfo?.clientPhone || 'N/A'}
                </Typography>
              </ExtendedDetails>
              <ExtendedDetails lastRow={true}>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.black,
                    fontWeight: 'bold'
                  }}
                >
                  Amount
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.primary.black }}>
                  {(billInfo && `Rwf ${billInfo?.salesTotal}`) || 'N/AA'}
                </Typography>
              </ExtendedDetails>
            </div>
          </StyledBottomDiv>

          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.primary,
              color: 'white'
            }}
            fullWidth
          >
            PRINT
          </Button>

          <Grid container justifyContent="center" alignItems="center" marginTop="2rem">
            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
              THANKS FOR CHOOSING US!
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export function BarBill({ billInfo, pay, update }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [hide, setHide] = useState('');
  const [tempBillInfo, setTempBillInfo] = useState(billInfo);
  const paymentType = determinePaymentType(billInfo);
  const [printRequest, { data }] = usePrintReceiptMutation();
  const userDetails = useSelector((state) => state.user.info);
  const [getCompany, companyResponse] = useLazyGetCompanyQuery();

  useEffect(() => {
    setTempBillInfo({
      ...billInfo,
      products:
        billInfo?.products?.map((product, index) => ({
          ...product,
          id: index + 1,
          itemName: product.productName,
          quantity: product.qty,
          suprice: product.unitPrice,
          stotal: product.totalPrice
        })) || []
    });

    if (!Object.isFrozen(billInfo) && !Object.isSealed(billInfo) && billInfo?.products) {
      billInfo.products = billInfo.products.map((product, index) => ({
        ...product,
        id: index + 1,
        itemName: product.productName,
        quantity: product.qty,
        suprice: product.unitPrice,
        stotal: product.totalPrice
      }));
    }

    if (billInfo?.saleCopyNum === 1) setHide('print');
    if (userDetails.companyId) getCompany({ id: userDetails.companyId });
  }, [billInfo, userDetails.companyId, getCompany]);

  if (hide === 'all' && data?.status) window.location.reload();

  const handlePrint = (value = '') => {
    const content = document.getElementById(`content-to-${value}`).innerHTML;
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`<html><head><title>${billInfo?.refCode}</title></head><body>`);
    newWindow.document.write(content);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    newWindow.print();
    setTimeout(() => {
      newWindow.close();
    }, 1000);

    printRequest({
      saleId: billInfo?.id,
      _type: billInfo?.recType === 'NR' ? billInfo?.recType : 'NS'
    });

    if (hide === '') setHide(value);
    else if (hide !== value) setHide('all');
  };

  const Line = <div style={{ borderBottom: '2px dotted', margin: '5px 0 5px 0' }} />;
  const Right = ({ children }) => <span style={{ float: 'right' }}>{children}</span>;
  const Flex = ({ children }) => <div style={{ display: 'flex' }}>{children}</div>;
  const Flex1 = ({ children }) => <div style={{ flex: 2 }}>{children}</div>;
  const Flex2 = ({ children }) => <div style={{ flex: 1, textAlign: 'center' }}>{children}</div>;
  const Flex3 = ({ children }) => <div style={{ flex: 1, textAlign: 'right' }}>{children}</div>;

  let totalA = 0;
  let totalB = 0;

  return (
    <div style={{ display: !tab ? 'block' : 'none' }}>
      <div id="content-to-print">
        <div
          style={{
            padding: 20,
            border: '2px solid',
            maxWidth: 500,
            margin: 'auto'
          }}
        >
          <div style={{ textAlign: 'center' }}>
            {companyResponse?.data?.data?.name} <br />
            {companyResponse?.data?.data?.locations} <br />
            TIN: {companyResponse?.data?.data?.tin} <br />
            <b>
              {billInfo?.recType === 'TS' ? 'TRAINING MODE' : ''} {billInfo?.title}
            </b>
          </div>
          {Line}
          {billInfo?.isRefunded === '1' ? (
            <>
              <div style={{ textAlign: 'center' }}>
                <b>REFUND</b>
              </div>
              <div>
                REF. NORMAL RECEIPT#:<Right>---</Right>
              </div>
              {Line}
              <div style={{ textAlign: 'center' }}>
                REFUND IS APPROVED ONLY FOR <br />
                ORIGINAL SALES RECEIPT <br />
                Client ID: {billInfo?.clientName}
              </div>
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>
              Welcome to our shop <br />
              Client ID: {billInfo?.clientName}
            </div>
          )}
          {Line}
          {tempBillInfo?.products?.map((item) => {
            if (item?.tax === 'A') totalA += item?.totalPrice || item?.unitPrice * item?.qty;
            if (item?.tax === 'B') totalB += item?.totalPrice || item?.unitPrice * item?.qty;
            return (
              <div key={item?.productId}>
                {item?.productName} <br />
                <Flex>
                  <Flex1>{item?.unitPrice} x</Flex1>
                  <Flex2>{item?.qty}</Flex2>
                  <Flex3>
                    {item?.totalPrice || item?.unitPrice * item?.qty}
                    {item?.tax === 'A' ? 'A-EX' : item?.tax}
                  </Flex3>
                </Flex>
              </div>
            );
          })}
          {Line}
          {billInfo?.recType === 'TS' && (
            <>
              <div style={{ textAlign: 'center' }}>
                <b>THIS IS NOT AN OFFICIAL RECEIPT</b>
              </div>
              {Line}
            </>
          )}
          <div>
            <b>TOTAL</b>
            <b style={{ float: 'right' }}>{billInfo?.salesTotal}</b>
          </div>
          <div>
            TOTAL A-EX<Right>{totalA}</Right>
          </div>
          <div>
            TOTAL B-18.00%
            <Right>{totalB}</Right>
          </div>
          <div>
            TOTAL TAX B<Right>{totalB * 0.18}</Right>
          </div>
          <div>
            TOTAL TAX<Right>{totalB * 0.18}</Right>
          </div>
          {Line}
          <div>
            CASH<Right>---</Right>
          </div>
          <div>
            ITEMS NUMBER<Right>{tempBillInfo?.products?.length}</Right>
          </div>
          {Line}
          {(billInfo?.title === 'COPY' || billInfo?.recType === 'TS') && (
            <>
              <div style={{ textAlign: 'center' }}>
                <b>
                  {billInfo?.recType === 'TS' ? 'TRAINING MODE' : ''} {billInfo?.title}
                </b>
              </div>
              {Line}
            </>
          )}
          <div style={{ textAlign: 'center' }}>VSDC INFORMATION</div>
          <div>
            DATE: ---<Right>Time: ---</Right>
          </div>
          <div>
            VSDC ID: <Right>---</Right>
          </div>
          <div>
            RECEIPT NUMBER: <Right>---</Right>
          </div>
          {Line}
          <div>
            RECEIPT NUMBER: <Right>{billInfo?.refCode}</Right>
          </div>
          <div>
            DATE: {billInfo?.createdAt?.split('T')[0]}
            <Right>TIME: {billInfo?.createdAt?.split('T')[1]?.slice(0, 8)}</Right>
          </div>
          <div>
            MRC: <Right>---</Right>
          </div>
          {Line}
          <div style={{ textAlign: 'center' }}>
            THANK YOU <br /> WE APPRECIATE YOUR BUSINESS
          </div>
        </div>
      </div>
      <div id="content-to-copy" style={{ display: 'none' }}>
        <div
          style={{
            padding: 20,
            border: '2px solid',
            maxWidth: 500,
            margin: 'auto'
          }}
        >
          <div style={{ textAlign: 'center' }}>
            {companyResponse?.data?.data?.name} <br />
            {companyResponse?.data?.data?.locations} <br />
            TIN: {companyResponse?.data?.data?.tin} <br />
            <b>{billInfo?.recType === 'TS' ? 'TRAINING MODE' : ''} COPY</b>
          </div>
          {Line}
          {billInfo?.isRefunded === '1' ? (
            <>
              <div style={{ textAlign: 'center' }}>
                <b>REFUND</b>
              </div>
              <div>
                REF. NORMAL RECEIPT#:<Right>---</Right>
              </div>
              {Line}
              <div style={{ textAlign: 'center' }}>
                REFUND IS APPROVED ONLY FOR <br />
                ORIGINAL SALES RECEIPT <br />
                Client ID: {billInfo?.clientName}
              </div>
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>
              Welcome to our shop <br />
              Client ID: {billInfo?.clientName}
            </div>
          )}
          {Line}
          {tempBillInfo?.products?.map((item) => {
            if (item?.tax === 'A') totalA += item?.totalPrice || item?.unitPrice * item?.qty;
            if (item?.tax === 'B') totalB += item?.totalPrice || item?.unitPrice * item?.qty;
            return (
              <div key={item?.productId}>
                {item?.productName} <br />
                <Flex>
                  <Flex1>{item?.unitPrice} x</Flex1>
                  <Flex2>{item?.qty}</Flex2>
                  <Flex3>
                    {item?.totalPrice || item?.unitPrice * item?.qty}
                    {item?.tax === 'A' ? 'A-EX' : item?.tax}
                  </Flex3>
                </Flex>
              </div>
            );
          })}
          {Line}
          {billInfo?.recType === 'TS' && (
            <>
              <div style={{ textAlign: 'center' }}>
                <b>THIS IS NOT AN OFFICIAL RECEIPT</b>
              </div>
              {Line}
            </>
          )}
          <div>
            <b>TOTAL</b>
            <b style={{ float: 'right' }}>{billInfo?.salesTotal}</b>
          </div>
          <div>
            TOTAL A-EX<Right>{totalA}</Right>
          </div>
          <div>
            TOTAL B-18.00%
            <Right>{totalB}</Right>
          </div>
          <div>
            TOTAL TAX B<Right>{totalB * 0.18}</Right>
          </div>
          <div>
            TOTAL TAX<Right>{totalB * 0.18}</Right>
          </div>
          {Line}
          <div>
            CASH<Right>---</Right>
          </div>
          <div>
            ITEMS NUMBER<Right>{tempBillInfo?.products?.length}</Right>
          </div>
          {Line}
          <div style={{ textAlign: 'center' }}>
            <b>{billInfo?.recType === 'TS' ? 'TRAINING MODE' : ''} COPY</b>
          </div>
          {Line}
          <div style={{ textAlign: 'center' }}>VSDC INFORMATION</div>
          <div>
            DATE: ---<Right>Time: ---</Right>
          </div>
          <div>
            VSDC ID: <Right>---</Right>
          </div>
          <div>
            RECEIPT NUMBER: <Right>---</Right>
          </div>
          {Line}
          <div>
            RECEIPT NUMBER: <Right>{billInfo?.refCode}</Right>
          </div>
          <div>
            DATE: {billInfo?.createdAt?.split('T')[0]}
            <Right>TIME: {billInfo?.createdAt?.split('T')[1]?.slice(0, 8)}</Right>
          </div>
          <div>
            MRC: <Right>---</Right>
          </div>
          {Line}
          <div style={{ textAlign: 'center' }}>
            THANK YOU <br /> WE APPRECIATE YOUR BUSINESS
          </div>
        </div>
      </div>
      <br />
      {hide !== 'all' && (
        <Flex>
          <Flex2>
            {hide !== 'copy' && hide !== '' && (
              <Button variant="contained" onClick={() => handlePrint('copy')} sx={{ mr: 1 }}>
                COPY
              </Button>
            )}
            {hide !== 'print' && (
              <Button variant="contained" onClick={() => handlePrint('print')} sx={{ mr: 1 }}>
                PRINT INVOICE
              </Button>
            )}
            {billInfo?.status == 'draft' && (
              <>
                <Button variant="contained" color="success" onClick={pay}>
                  PAY NOW
                </Button>
                <Button variant="contained" color="warning" onClick={update} sx={{ mx: 1 }}>
                  UPDATE
                </Button>
              </>
            )}
          </Flex2>
        </Flex>
      )}
    </div>
  );
}

const StyledDiv = styled('div')(({ borderYes, borderWidth, borderBottom = 1.4 }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: `${borderBottom}rem`,
  paddingBottom: `${borderBottom}rem`,
  borderBottom: borderYes ? `${borderWidth}px solid #000000` : 'none'
}));
const StyledBottomDiv = styled('div')(({ borderYes, borderWidth, borderBottom = 1.4 }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: `${borderBottom}rem`,
  paddingBottom: `${borderBottom}rem`,
  borderBottom: borderYes ? `${borderWidth}px solid #000000` : 'none',
  width: '100%'
}));
const SupplierDetails = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const ExtendedDetails = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: (props) => (props.lastRow ? 'none' : '1px solid black'),
  padding: '1rem 0'
});
