import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';
import { TextInput, Select, Button } from '@mantine/core';
import DataTableWithTitle from 'component/DataTable';
import { Box, Card, FormHelperText, MenuItem, TextField } from '@mui/material';
import * as Yup from 'yup';
import {
  useCreateProductMutation,
  useGetProductCategoryQuery,
  useGetAllProductsQuery,
  useUpdateProductMutation
} from 'store/features/slices/productSlice';
import { useTheme } from '@mui/material/styles';
import LoadingButton from 'component/Loader/LoadingButton';
import ErrorMessageResponse from 'component/ErrorMessage/ErrorMessageResponse';
import Popover from '@mui/material/Popover';
import Iconify from 'component/iconify/iconify';
import IconButton from '@mui/material/IconButton';
import quantityList from '../../assets/unit-of-quantity.json';

function ProductList() {
  const classes = useStyles();
  const theme = useTheme();
  const [openId, setOpenId] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showError, setShowError] = React.useState(false);
  const [createProduct, { isLoading, isError, error, isSuccess }] = useCreateProductMutation();
  const [updateProduct, { isLoading: updateIsLoading, isError: updateIsError, error: updateError }] = useUpdateProductMutation();
  const { data: productCategoryList, isLoading: productCategoryLoading } = useGetProductCategoryQuery();
  const { data: productList, isLoading: productLoading } = useGetAllProductsQuery();
  const toggleShowError = () => setShowError(!showError);
  const [editDetails, setEditDetails] = React.useState(null);

  const columns = [
    { field: '_id', headerName: '#Index', flex: 1 },
    { field: 'name', headerName: 'Product Name', flex: 1 },
    {
      field: 'product_category',
      headerName: 'Category',
      flex: 1,
      valueGetter: (params) => params.row?.product_category?.name
    },
    { field: 'unit', headerName: 'Quantity', flex: 1 },
    { field: 'tax', headerName: 'Product Tax', flex: 1 },
    { field: 'min_qty', headerName: 'Reorder Level', flex: 1 },
    { field: 'notifyExpirationBefore', headerName: 'Notify Days', flex: 1 },
    {
      field: '',
      headerName: '',
      sortable: false,

      renderCell: (params) => (
        <>
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setOpenId(params?.row?.id);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>

          <Popover
            anchorEl={anchorEl}
            open={params?.row?.id === openId}
            onClose={() => {
              setAnchorEl(null);
              setOpenId(0);
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{ sx: { width: 140 } }}
          >
            <MenuItem
              onClick={() => {
                setEditDetails(params?.row);
                setAnchorEl(null);
                setOpenId(0);
              }}
            >
              <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
              Edit
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }}>
              <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </>
      )
    }
  ];

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item lg={8} xs={12}>
          <Card sx={{ height: '100%', padding: '20px' }}>
            <DataTableWithTitle
              title="Product List"
              columns={columns}
              rows={(productList && productList?.count > 0 && productList?.rows?.length > 0 && productList.rows) || []}
              searchable={true}
              download={false}
            />
          </Card>
        </Grid>
        <Grid item lg={4} xs={12}>
          {editDetails === null ? (
            <div className={classes.customCard}>
              <Typography variant="h6" className={classes.title}>
                New Product
              </Typography>
              <Typography variant="body1" className={classes.Subtitle}>
                Add New Product
              </Typography>
              <Formik
                initialValues={{
                  name: '',
                  unit: '',
                  min_qty: '',
                  tax: '',
                  notifyExpirationBefore: '',
                  productCategoryId: ''
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Product  name is required'),
                  productCategoryId: Yup.string().required('Select Product Category'),
                  unit: Yup.string().required('Quantity Type is required'),
                  min_qty: Yup.string().required('Product reorder level is required'),
                  tax: Yup.string().required('Select Product Tax'),
                  notifyExpirationBefore: Yup.string().required('Notify before Days is required')
                })}
                onSubmit={async (values, actions) => {
                  try {
                    const result = await createProduct(values).unwrap();
                    actions.resetForm();
                    actions.setSubmitting(false);
                  } catch (err) {
                    actions.setSubmitting(false);
                    toggleShowError(true);
                  } finally {
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <TextField
                      error={Boolean(touched.productCategoryId && errors.productCategoryId)}
                      select
                      helperText={touched.productCategoryId && errors.productCategoryId}
                      fullWidth
                      label="Product Category"
                      margin="normal"
                      name="productCategoryId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.productCategoryId}
                      variant="outlined"
                    >
                      <MenuItem disabled>Select Product Category</MenuItem>
                      {productCategoryLoading ? (
                        <MenuItem disabled>Loading...</MenuItem>
                      ) : productCategoryList?.rows && productCategoryList?.rows?.length > 0 ? (
                        productCategoryList?.rows?.map((productCat) => (
                          <MenuItem key={productCat?.id} value={productCat?.id}>
                            {productCat?.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No product types found!</MenuItem>
                      )}
                    </TextField>

                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Product name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />

                    <TextField
                      error={Boolean(touched.unit && errors.unit)}
                      select
                      fullWidth
                      helperText={touched.unit && errors.unit}
                      label="Quantity type"
                      margin="normal"
                      name="unit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.unit}
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>
                        Select Quantity Type
                      </MenuItem>
                      {quantityList?.map((item) => (
                        <MenuItem key={item?.Code} value={item?.Code}>
                          {item['Code Name']} ({item?.Code})
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      error={Boolean(touched.tax && errors.tax)}
                      select
                      helperText={touched.tax && errors.tax}
                      fullWidth
                      label="Product Tax"
                      margin="normal"
                      name="tax"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.tax}
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>
                        Select Product Tax
                      </MenuItem>
                      <MenuItem value="A">A-EX</MenuItem>
                      <MenuItem value="B">B-18%</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                    </TextField>
                    <TextField
                      error={Boolean(touched.min_qty && errors.min_qty)}
                      fullWidth
                      helperText={touched.min_qty && errors.min_qty}
                      label="Reorder level / Minimum Quantity"
                      margin="normal"
                      name="min_qty"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.min_qty}
                      variant="outlined"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                    />

                    <TextField
                      error={Boolean(touched.notifyExpirationBefore && errors.notifyExpirationBefore)}
                      fullWidth
                      helperText={touched.notifyExpirationBefore && errors.notifyExpirationBefore}
                      label="Notify Before Expiration (Days)"
                      margin="normal"
                      name="notifyExpirationBefore"
                      onBlur={handleBlur}
                      type="number"
                      onChange={handleChange}
                      value={values.notifyExpirationBefore}
                      variant="outlined"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                    />

                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <LoadingButton
                        sx={{
                          backgroundColor: theme.palette.primary.customMain,
                          color: 'white'
                        }}
                        loading={isSubmitting}
                        done={false}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </LoadingButton>
                    </Box>
                    {showError && !isLoading && isError && (
                      <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                        <ErrorMessageResponse>{error?.data?.message || error.message || 'An error occurred'}</ErrorMessageResponse>
                      </Grid>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          ) : (
            <div className={classes.customCard}>
              <Typography variant="h6" className={classes.title}>
                Product List
              </Typography>
              <Typography variant="body1" className={classes.Subtitle}>
                Update Product
              </Typography>
              <Formik
                initialValues={{
                  name: editDetails ? editDetails.name : '',
                  unit: editDetails ? editDetails.unit : '',
                  min_qty: editDetails ? editDetails.min_qty : '',
                  tax: editDetails ? editDetails.tax : '',
                  notifyExpirationBefore: editDetails ? editDetails.notifyExpirationBefore : '',
                  productCategoryId: editDetails ? editDetails.productCategoryId : '',
                  image: editDetails ? editDetails.image : '',
                  _productId: editDetails ? editDetails.id : ''
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Product  name is required'),
                  productCategoryId: Yup.string().required('Select Product Category'),
                  unit: Yup.string().required('Quantity Type is required'),
                  min_qty: Yup.string().required('Product reorder level is required'),
                  tax: Yup.string().required('Select Product Tax'),
                  notifyExpirationBefore: Yup.string().required('Notify before Days is required')
                })}
                onSubmit={async (values, actions) => {
                  try {
                    const result = await updateProduct(values).unwrap();
                    actions.resetForm();
                    setEditDetails(null);
                    actions.setSubmitting(false);
                  } catch (err) {
                    actions.setSubmitting(false);
                    toggleShowError(true);
                  } finally {
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, resetForm }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <TextField
                      error={Boolean(touched.productCategoryId && errors.productCategoryId)}
                      select
                      helperText={touched.productCategoryId && errors.productCategoryId}
                      fullWidth
                      label="Product Category"
                      margin="normal"
                      name="productCategoryId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.productCategoryId}
                      variant="outlined"
                    >
                      <MenuItem disabled>Select Product Category</MenuItem>
                      {productCategoryLoading ? (
                        <MenuItem disabled>Loading...</MenuItem>
                      ) : productCategoryList?.rows && productCategoryList?.rows?.length > 0 ? (
                        productCategoryList?.rows?.map((productCat) => (
                          <MenuItem key={productCat?.id} value={productCat?.id}>
                            {productCat?.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No product types found!</MenuItem>
                      )}
                    </TextField>

                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Product name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />

                    <TextField
                      error={Boolean(touched.unit && errors.unit)}
                      select
                      fullWidth
                      helperText={touched.unit && errors.unit}
                      label="Quantity type"
                      margin="normal"
                      name="unit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.unit}
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>
                        Select Quantity Type
                      </MenuItem>
                      {quantityList?.map((item) => (
                        <MenuItem key={item?.Code} value={item?.Code}>
                          {item['Code Name']} ({item?.Code})
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      error={Boolean(touched.tax && errors.tax)}
                      select
                      helperText={touched.tax && errors.tax}
                      fullWidth
                      label="Product Tax"
                      margin="normal"
                      name="tax"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.tax}
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>
                        Select Product Tax
                      </MenuItem>
                      <MenuItem value="A">A-EX</MenuItem>
                      <MenuItem value="B">B-18%</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                    </TextField>
                    <TextField
                      error={Boolean(touched.min_qty && errors.min_qty)}
                      fullWidth
                      helperText={touched.min_qty && errors.min_qty}
                      label="Reorder level / Minimum Quantity"
                      margin="normal"
                      name="min_qty"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.min_qty}
                      variant="outlined"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                    />

                    <TextField
                      error={Boolean(touched.notifyExpirationBefore && errors.notifyExpirationBefore)}
                      fullWidth
                      helperText={touched.notifyExpirationBefore && errors.notifyExpirationBefore}
                      label="Notify Before Expiration (Days)"
                      margin="normal"
                      name="notifyExpirationBefore"
                      onBlur={handleBlur}
                      type="number"
                      onChange={handleChange}
                      value={values.notifyExpirationBefore}
                      variant="outlined"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                    />

                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2} display="flex" justifyContent="space-between">
                      <LoadingButton
                        sx={{
                          backgroundColor: theme.palette.error.main,
                          color: 'white'
                        }}
                        loading={isSubmitting}
                        done={false}
                        size="large"
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditDetails(null);
                          resetForm();
                        }}
                      >
                        Quit Update
                      </LoadingButton>
                      <LoadingButton
                        sx={{
                          backgroundColor: theme.palette.primary.customMain,
                          color: 'white'
                        }}
                        loading={isSubmitting}
                        done={false}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </LoadingButton>
                    </Box>
                    {showError && !updateIsLoading && updateIsError && (
                      <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                        <ErrorMessageResponse>
                          {updateError?.data?.message || updateError.message || 'An error occurred'}
                        </ErrorMessageResponse>
                      </Grid>
                    )}

                    {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                  </form>
                )}
              </Formik>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  customCard: {
    backgroundColor: '#F9F9F9',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    padding: '20px'
  },
  title: {
    fontSize: '16px',
    color: '#333'
  },

  Subtitle: {
    fontSize: '14px',
    color: '#333',
    marginBottom: '1rem'
  },
  formField: {
    marginBottom: '20px'
  },
  submitButton: {
    backgroundColor: 'background: rgba(18, 9, 88, 1)',
    color: '#FFF',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'background-color 0.3s'
  }
}));

export default ProductList;
