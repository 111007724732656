import { useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { Modal, PasswordInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import { users } from '_mock/user';
import Iconify from 'component/iconify/iconify';
import Scrollbar from 'component/scrollbar/scrollbar';

import TableNoData from '../table-no-data';
import UserTableRow, { BarTableRow } from '../user-table-row';
import UserTableHead from '../user-table-head';
import TableEmptyRows from '../table-empty-rows';
import UserTableToolbar from '../user-table-toolbar';
import { emptyRows, applyFilter, getComparator } from '../utils';
import { useTheme } from '@mui/material/styles';
import LoadingButton from 'component/Loader/LoadingButton';
import { Box, Grid } from '@mui/material';
import ErrorMessageResponse from 'component/ErrorMessage/ErrorMessageResponse';
import { CustomInput } from 'component/inputs/TextInput';
import {
  useCreateCompanySuppliersMutation,
  useGetAllCompanySupplierQuery,
  useUpdateCompanyUserMutation,
  useUpdateCompanySuppliersMutation,
  useGetBarTablesQuery,
  useCreateBarTableMutation,
  useUpdateBarTableMutation
} from 'store/features/slices/companySlice';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import SelectInput from 'component/inputs/SelectInput';

// TODO: minimize
export default function SupplierPage() {
  const theme = useTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [showError, setShowError] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(null);
  const toggleShowError = () => setShowError(!showError);
  const { data: companyUserSupplier = [], isLoading: expenseLoading } = useGetAllCompanySupplierQuery();
  const [createCompanySupplier, { isLoading, isError, error, isSuccess }] = useCreateCompanySuppliersMutation();
  const [updateCompanySupplier, { isLoading: updateSupplierIsLoading, isError: updateSupplierIsError, error: updateSupplierError }] =
    useUpdateCompanySuppliersMutation();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = companyUserSupplier.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: companyUserSupplier,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const handleUpdateDetails = (data) => {
    setUpdateDetails(data);
    setupdateModal(true);
  };

  const notFound = !dataFiltered.length && !!filterName;

  return (
    <Container>
      <Modal
        opened={opened}
        onClose={() => {
          close();
        }}
        size="xl"
        centered
        closeOnClickOutside={false}
      >
        <Box p={4}>
          <Formik
            initialValues={{
              names: '',
              email: '',
              phone: '',
              homeAddress: '',
              country: '',
              tin: ''
            }}
            validationSchema={Yup.object().shape({
              names: Yup.string().required('First name is required')
            })}
            onSubmit={async (values, actions) => {
              try {
                const result = await createCompanySupplier(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                close();
                notifications.show({
                  id: 'company-user',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Campany User Creation',
                  message: `User Created Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid item xs={12}>
                  <Box marginBottom="1rem">
                    <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                      Supplier Details
                    </Typography>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <CustomInput name="names" label="Supplier Name" placeholder="Enter First Name" readOnly={false} withAsterisk={true} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="tin" label="Supplier Tin" placeholder="Enter Tin number" readOnly={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="email" label="Supplier Email" placeholder="Enter Email Address" readOnly={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="phone" label="Phone Number" placeholder="Enter Phone Number" readOnly={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="homeAddress" label="Supplier Address" placeholder="Enter address " readOnly={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="country" label="Country" placeholder="Enter country " readOnly={false} />
                    </Grid>
                  </Grid>
                </Grid>

                {showError && !isLoading && isError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>{error?.data?.message || error.message || 'An error occurred'}</ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.customMain, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    SUBMIT
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        opened={updateModal}
        onClose={() => {
          setupdateModal(false);
          setUpdateDetails(null);
          toggleShowError(false);
        }}
        size="xl"
        centered
        closeOnClickOutside={false}
      >
        <Box p={4}>
          <Formik
            initialValues={{
              names: updateDetails?.names,
              email: updateDetails?.email,
              phone: updateDetails?.phone,
              homeAddress: updateDetails?.homeAddress,
              country: updateDetails?.country,
              tin: updateDetails?.tin,
              _supplierId: updateDetails?.id
            }}
            validationSchema={Yup.object().shape({
              names: Yup.string().required('First name is required')
            })}
            onSubmit={async (values, actions) => {
              try {
                const result = await updateCompanySupplier(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                setupdateModal(false);
                notifications.show({
                  id: 'company-update',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Supplier Update',
                  message: `Supplier Updated Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid item xs={12}>
                  <Box marginBottom="1rem">
                    <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                      Update Supplier Details
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <CustomInput name="names" label="Supplier Name" placeholder="Enter First Name" readOnly={false} withAsterisk={true} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="tin" label="Supplier Tin" placeholder="Enter Tin number" readOnly={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="email" label="Supplier Email" placeholder="Enter Email Address" readOnly={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="phone" label="Phone Number" placeholder="Enter Phone Number" readOnly={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="homeAddress" label="Supplier Address" placeholder="Enter address " readOnly={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="country" label="Country" placeholder="Enter country " readOnly={false} />
                    </Grid>
                  </Grid>
                </Grid>

                {showError && !updateSupplierIsLoading && updateSupplierIsError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>
                      {updateSupplierError?.data?.message || updateSupplierError.message || 'An error occurred'}
                    </ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.customMain, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4">Suppliers</Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.primary.customMain, color: 'white' }}
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => open()}
        >
          New Supplier
        </Button>
      </Stack>
      <Card>
        <UserTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Table sx={{ minWidth: 800 }}>
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={companyUserSupplier.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[
                  { id: 'name', label: 'Name' },
                  { id: 'country', label: 'Country' },
                  { id: 'company', label: 'Phone' },
                  { id: 'email', label: 'Email' },
                  { id: '' }
                ]}
              />
              <TableBody>
                {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <UserTableRow
                    key={row.id}
                    name={row?.names}
                    role={row.role}
                    country={row.country}
                    company={row.phone}
                    email={row.email}
                    all={row}
                    avatarUrl={row.avatarUrl}
                    selected={selected.indexOf(row.name) !== -1}
                    handleClick={(event) => handleClick(event, row.name)}
                    handleUpdateDetails={handleUpdateDetails}
                  />
                ))}

                <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, companyUserSupplier.length)} />

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          page={page}
          component="div"
          count={companyUserSupplier.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}

export function BarTablePage() {
  const theme = useTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [showError, setShowError] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(null);
  const toggleShowError = () => setShowError(!showError);
  const { data: companyUserSupplier = [], isLoading: expenseLoading } = useGetBarTablesQuery();
  const [createTable, { isLoading, isError, error, isSuccess }] = useCreateBarTableMutation();
  const [updateTable, { isLoading: updateSupplierIsLoading, isError: updateSupplierIsError, error: updateSupplierError }] =
    useUpdateBarTableMutation();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = companyUserSupplier.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: companyUserSupplier,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const handleUpdateDetails = (data) => {
    setUpdateDetails(data);
    setupdateModal(true);
  };

  const notFound = !dataFiltered.length && !!filterName;

  return (
    <Container>
      <Modal
        opened={opened}
        onClose={() => {
          close();
        }}
        size="sm"
        centered
        closeOnClickOutside={false}
      >
        <Box p={4}>
          <Formik
            initialValues={{ name: '' }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Table name is required')
            })}
            onSubmit={async (values, actions) => {
              try {
                await createTable(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                close();
                notifications.show({
                  id: 'bar-table',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Table Created',
                  message: `Table Created Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid item xs={12}>
                  <Box marginBottom="1rem">
                    <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                      Bar Table
                    </Typography>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <CustomInput name="name" label="Table Name" placeholder="Enter Table Name" readOnly={false} withAsterisk={true} />
                    </Grid>
                  </Grid>
                </Grid>

                {showError && !isLoading && isError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>{error?.data?.message || error.message || 'An error occurred'}</ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.customMain, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    SUBMIT
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        opened={updateModal}
        onClose={() => {
          setupdateModal(false);
          setUpdateDetails(null);
          toggleShowError(false);
        }}
        size="sm"
        centered
        closeOnClickOutside={false}
      >
        <Box p={4}>
          <Formik
            initialValues={{
              name: updateDetails?.name
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Table name is required')
            })}
            onSubmit={async (values, actions) => {
              try {
                await updateTable(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                setupdateModal(false);
                notifications.show({
                  id: 'bar-table',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Table Update',
                  message: `Table Updated Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid item xs={12}>
                  <Box marginBottom="1rem">
                    <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                      Update Bar Table
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <CustomInput name="name" label="Table Name" placeholder="Enter Table Name" readOnly={false} withAsterisk={true} />
                    </Grid>
                  </Grid>
                </Grid>

                {showError && !updateSupplierIsLoading && updateSupplierIsError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>
                      {updateSupplierError?.data?.message || updateSupplierError.message || 'An error occurred'}
                    </ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.customMain, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4">Bar Tables</Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.primary.customMain, color: 'white' }}
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => open()}
        >
          New Table
        </Button>
      </Stack>
      <Card>
        <UserTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Table sx={{ minWidth: 800 }}>
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={companyUserSupplier.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[{ id: 'name', label: 'Name' }, { id: '' }]}
              />
              <TableBody>
                {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <BarTableRow
                    all={row}
                    key={row.id}
                    name={row?.name}
                    selected={selected.indexOf(row.name) !== -1}
                    handleClick={(event) => handleClick(event, row.name)}
                    handleUpdateDetails={handleUpdateDetails}
                  />
                ))}

                <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, companyUserSupplier.length)} />

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          page={page}
          component="div"
          count={companyUserSupplier.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
