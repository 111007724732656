import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';

export const productApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProduction: builder.query({
      query: () => 'shop/sales/all-production?limit=100',
      refetchOnReconnect: true,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response?.data?.rows || [],
      overrideExisting: true
    }),
    createProduction: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/sales/create-production',
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['production', 'change-product-status', 'sales', 'purchase', 'product-list'],
      overrideExisting: true
    }),
    createProduct: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/product/create-product',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['product-list']
    }),
    updateProduct: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/product/update-product',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['product-list']
    }),
    createProductCategory: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/product/create-category',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['product-category']
    }),
    updateProductCategory: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/product/update-category',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['product-category']
    }),
    createProductType: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/product/create-category-type',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['purchases-type']
    }),
    UpdateStockProductStatus: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/product/change-stock-status',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['change-product-status'],
      overrideExisting: true
    }),

    UpdateStockProductDetails: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/product/update-stock',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['product-list'],
      overrideExisting: true
    }),

    getAllProducts: builder.query({
      query: (id) => {
        let apiUrl = 'shop/product/get-products/categoryId';
        if (id) {
          apiUrl += `/${id}`;
        } else {
          apiUrl += '/0';
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['product-list', 'change-product-status']
    }),
    getProductType: builder.query({
      query: () => 'shop/product/get-category-type',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['purchases-type']
    }),
    getProductCategory: builder.query({
      query: (id) => {
        let apiUrl = 'shop/product/get-category/typeId';
        if (id) {
          apiUrl += `/${id}`;
        } else {
          apiUrl += '/0';
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['product-category']
    }),
    getStock: builder.query({
      query: () => 'shop/product/get-stock?limit=100',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response?.data?.rows || [],
      providesTags: ['change-product-status', 'sales', 'purchase', 'product-list'],
      overrideExisting: true
    }),
    getSearchedItem: builder.query({
      query: (search) => {
        if (search) {
          return `shop/product/get-stock?q=${search}`;
        }
        return 'shop/product/get-stock?limit=100';
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      // pollingInterval: 15 * 60 * 1000,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data
    }),

    getAllToBeReOrderedList: builder.query({
      query: () => 'shop/totals/dashboard/listByName/toBeReOrdered?limit=100',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data && response?.data?.length > 0 ? response?.data?.sort((a, b) => b.id - a.id) : []
    }),
    getAllToBeExpired: builder.query({
      query: () => 'shop/totals/dashboard/listByName/toBeExpired?limit=100',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data && response?.data?.length > 0 ? response?.data?.sort((a, b) => b.id - a.id) : []
    }),

    getAllExpiredAndDamaged: builder.query({
      query: (filterDates) => {
        let apiUrl = 'shop/product/get-damaged-expired-product?limit=100';
        if (filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => {
        if (response.error) {
          console.error(response.error);
          throw new Error('Failed to fetch data');
        }
        return response?.data?.rows && response?.data?.rows?.length > 0 ? response?.data?.rows.sort((a, b) => b.id - a.id) : [];
      },
      providesTags: ['change-product-status'],
      overrideExisting: true
    }),

    getStockTotals: builder.query({
      query: (filterDates) => {
        let apiUrl = 'shop/totals/salesTotal?name=stockAll';
        if (filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      refetchOnReconnect: true,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales', 'change-product-status'],
      overrideExisting: true
    }),
    getLossTotals: builder.query({
      query: (filterDates) => {
        let apiUrl = 'shop/totals/salesTotal?name=stockLoss';
        if (filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      refetchOnReconnect: true,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales', 'change-product-status'],
      overrideExisting: true
    })
  })
});

export const {
  useCreateProductMutation,
  useCreateProductCategoryMutation,
  useCreateProductTypeMutation,
  useGetProductTypeQuery,
  useGetProductCategoryQuery,
  useCreateProductionMutation,
  useGetAllProductsQuery,
  useGetStockQuery,
  useGetProductionQuery,
  useGetSearchedItemQuery,
  useGetAllToBeExpiredQuery,
  useLazyGetAllToBeExpiredQuery,
  useGetAllToBeReOrderedListQuery,
  useGetAllExpiredAndDamagedQuery,
  useUpdateStockProductStatusMutation,
  useLazyGetAllExpiredAndDamagedQuery,
  useUpdateStockProductDetailsMutation,
  useLazyGetStockTotalsQuery,
  useLazyGetLossTotalsQuery,
  useUpdateProductCategoryMutation,
  useUpdateProductMutation
} = productApi;

const productSlice = createSlice({
  name: 'product',
  initialState: {
    product: [],
    stock: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(productApi.endpoints.getStock.matchFulfilled, (state, action) => {
      state.stock = action?.payload?.data;
    });
  }
});

export const productReducer = productSlice.reducer;

export default productSlice;
